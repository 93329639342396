@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* ------ Add your CSS below this line (or @import your other CSS files). ------- */

@import '../../node_modules/swiper/swiper.min.css';

html {
  @apply scroll-smooth scroll-p-10;
}

body {
  @apply font-primary text-[#262626];
}

.hl-text {
  @apply relative box-decoration-clone px-1 bg-gradient-to-b from-[#FDE749] to-[#FDE749] bg-[length:100%_12px] bg-no-repeat bg-left-bottom md:bg-[length:100%_15px];
  /* before:pointer-events-none before:absolute before:left-0 before:right-0 before:bottom-0 before:mix-blend-multiply before:bg-[#FDE749] before:h-3 before:z-10 */
}

.dotted-text {
  @apply inline-block relative before:absolute before:pointer-events-none before:w-1.5 before:h-1.5 before:rounded-full before:bg-current before:left-1/2 before:-translate-x-1/2;
}

/* Swiper */
.swiper-slide {
  @apply h-auto;
}

.swiper-prev-button,
.swiper-next-button {
  @apply absolute w-5 h-16 top-1/3 cursor-pointer opacity-60 bg-contain bg-no-repeat bg-center z-10;
}

.swiper-prev-button {
  @apply -left-3 bg-[url('../img/arrow-prev-button.svg')];
}

.swiper-next-button {
  @apply -right-3 bg-[url('../img/arrow-next-button.svg')];
}

.swiper-pagination-bullets {
  @apply flex items-center justify-center;
}

.swiper-pagination-bullet {
  @apply w-6 h-6 relative cursor-pointer before:pointer-events-none before:absolute before:w-2 before:h-2 before:left-1/2 before:top-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:border before:border-[#585858] before:rounded-full;
}

.swiper-pagination-bullet-active {
  @apply before:bg-[#585858];
}

/* Main Header Nav */
.main-header-nav.active {
  @apply translate-x-0;
}

.js-menu-trigger span:nth-child(1),
.js-menu-trigger span:nth-child(3) {
  @apply transition-all duration-150 ease-out;
}
.js-menu-trigger span:nth-child(2) {
  @apply transition-transform duration-150 ease-out origin-left;
}

.js-menu-trigger.active span:nth-child(1) {
  @apply top-1/2 rotate-45 delay-150;
}

.js-menu-trigger.active span:nth-child(2) {
  @apply scale-x-0;
}

.js-menu-trigger.active span:nth-child(3) {
  @apply top-1/2 -rotate-45 delay-150;
}

.main-header-nav a {
  @apply inline-block py-0.5 relative after:pointer-events-none after:absolute after:left-0 after:top-full after:h-[3px] after:w-full after:bg-[#FEE934] after:scale-0 after:transition-all after:duration-200 after:ease-in-out;
}

.main-header-nav a.active {
  @apply font-bold opacity-100 md:text-[#242424] after:scale-100;
}

/* Form */
.form-row {
  @apply md:flex;
}

label {
  @apply cursor-pointer font-bold flex items-baseline;
}

label.req-badge {
  @apply after:ml-4 after:content-['必須'] after:text-sm after:text-white after:bg-[#F5222D] after:px-2 after:py-0.5 md:after:py-1 md:justify-between;
}

.input-wrapper {
  @apply flex-1 relative;
}

.form-row input,
.form-row textarea,
.form-row select {
  @apply bg-[#F5F5F5] border border-[#E7E7E7] rounded-sm w-full placeholder:text-[#BEBEBE] focus-within:bg-white valid:bg-white;
}

.form-row select {
  @apply cursor-pointer appearance-none bg-[url('../img/icon-dropdown.svg')] bg-no-repeat bg-[right_20px_center];
}

/* Seminar */
.seminar-popup.active {
  @apply opacity-100 pointer-events-auto;
}

.seminar-popup.active > .seminar-popup__container {
  @apply opacity-100 translate-y-0;
}

/* Faqs */
.faq-item {
  @apply py-10;
}

.faq-item.active .question {
  @apply after:bg-[url('../img/icon-question-opened.svg')];
}

.faq-item.active .answer {
  @apply flex;
}

.question {
  @apply cursor-pointer text-[#4d4d4d] flex items-center gap-6 before:flex-none before:font-secondary before:content-['Q'] before:text-[#1DAEFC] before:font-bold before:text-[32px] after:hidden after:flex-none after:w-9 after:h-9 after:bg-contain after:bg-[url('../img/icon-question-closed.svg')] after:rounded-full after:ml-auto md:after:block hover:text-[#262626];
}

.answer {
  @apply hidden pt-4 items-start gap-6 before:flex-none before:font-secondary before:content-['A'] before:text-[#FEC034] before:font-bold before:text-[32px] before:leading-none;
}

.answer__container {
  @apply space-y-2;
}
